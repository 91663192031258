import { createTheme } from '@mui/material/styles'

import palette from '/src/theme/palette'
import { setTypography } from '/src/theme/typography'
import { getClamp } from '/src/utils/functions'

import AgrandirBold from '/static/fonts/Agrandir/Agrandir-Bold.woff2'
import AgrandirLight from '/static/fonts/Agrandir/Agrandir-Light.woff2'
import AgrandirRegular from '/static/fonts/Agrandir/Agrandir-Regular.woff2'
import AgrandirWide from '/static/fonts/Agrandir/Agrandir-Wide.woff2'

const theme = createTheme({
    typography: {
        fontFamily: [
            "'AgrandirRegular', sans serif"
        ].join(',')
    },
    palette,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1920
        }
    },
    zIndex: {
        loading: 1600,
        transition: 1599,
        cursor: 1700
    },
    components: {
        // // Name of the component
        // MuiButton: {
        //     styleOverrides: {
        //         // Name of the slot
        //         root: {
        //             // Some CSS
        //             fontSize: '1rem'
        //         }
        //     }
        // },
        MuiButton: {
            styleOverrides: {
                sizeSmall: {
                    fontFamily: 'AgrandirWide',
                    fontSize: getClamp(17, 48),
                    lineHeight: getClamp(23, 56),
                    borderRadius: '43px',
                    textTransform: 'none',
                    padding: getClamp(10, 16) + ' ' + getClamp(50, 83),
                    '@media (max-width:600px)': {
                        borderRadius: '50%',
                    }
                },
                sizeMedium: {
                    fontFamily: 'AgrandirWide',
                    fontSize: getClamp(17, 88),
                    lineHeight: getClamp(20, 88),
                    borderRadius: '80px',
                    textTransform: 'none',
                    padding: getClamp(10, 20) + ' ' + getClamp(53, 80),
                    '@media (max-width:600px)': {
                        borderRadius: '50%',
                    }
                },
                sizeLarge: {
                    fontFamily: 'AgrandirWide',
                    fontSize: getClamp(32, 160),
                    lineHeight: getClamp(35, 164),
                    borderRadius: '800px',
                    textTransform: 'none',
                    padding: getClamp(10, 48) + ' ' + getClamp(30, 120),
                    '@media (max-width:600px)': {
                        borderRadius: '50%',
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    textDecoration: 'none'
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '::before': {
                        top: '-3px',
                        height: '3px',
                        '@media (max-width:600px)': {
                            top: '-1px',
                            height: '1px'
                        }
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    paddingLeft: 0
                },
                content: {
                    margin: 0
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#000',
                    borderBottomWidth: '3px',
                    '@media (max-width:600px)': {
                        borderBottomWidth: '1px'
                    }
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    overflowX: 'scroll'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'AgrandirBold';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 700;
                    src: url('${AgrandirBold}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'AgrandirLight';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 300;
                    src: url('${AgrandirLight}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'AgrandirRegular';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 400;
                    src: url('${AgrandirRegular}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'AgrandirWide';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 400;
                    src: url('${AgrandirWide}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
            `,
        }
    }
});

setTypography(theme);

// theme.spacing(1);

// mobile stepper: 1000
// speed dial: 1050
// app bar: 1100
// drawer: 1200
// modal: 1300
// snackbar: 1400
// tooltip: 1500

// export default responsiveFontSizes(theme);
// console.info(theme);

export default theme;