import React from 'react'
import TopLayout from '/src/components/topLayout'
import WrapLayout from '/src/components/wrapLayout'

export const wrapRootElement = ({ element }) => {
    return <TopLayout>{element}</TopLayout>
};

export const wrapPageElement = ({ element, props }) => {
    return <WrapLayout {...props}>{element}</WrapLayout>
};

export const onInitialClientRender = () => {
    window.scrollTo(0, 0);
}

export const onRouteUpdate = () => {
    window.scrollTo(0, 0);
}

export const shouldUpdateScroll = () => {
    window.scrollTo(0, 0);
    return false
}