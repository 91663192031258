import React, { useRef, useEffect, useContext } from 'react'

import parse from 'html-react-parser'
import CookieConsent from 'react-cookie-consent'
import { useCookies } from 'react-cookie'

// MUI
import { useTheme, width } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box, SvgIcon, Button } from '@mui/material'

import CustomCursor from '/src/components/lib/cursor'
import Transition from '/src/components/transition'
import TransitionMobile from '/src/components/transitionMobile'
import IconLongHorizontalArrow from '/static/icons/icon-long-horizontal-arrow.svg'

import attachCustomCursor from '/src/utils/attachCustomCursor'
import palette from '/src/theme/palette'
import { transitionColor } from '/src/theme/setting'
import { getClamp } from '/src/utils/functions'
import { MouseContext } from '/src/context/mouseContext'
import { getUrl } from '/src/utils/functions'

const BoxCookie = styled( Box )(({ theme }) => ({
    '& .container-cookie-consent': {
        padding: getClamp(32, 48),
        position: 'reltive',
        ...theme.typography.textm,
        [theme.breakpoints.down('md')]: {
            width: '90% !important'
        },
        '& > div:first-child': {
            color: palette.bbr.almostwhite,
            ...theme.typography.textswide,
        },
        '& a': {
            color: palette.bbr.almostwhite,
            ...theme.typography.textswide,
        },
        '& #rcc-confirm-button': {
            ...theme.typography.h6wide,
            color: palette.bbr.almostwhite,
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            marginTop: getClamp(21, 80),
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& .cookie-icon': {
            height: 'auto',
            width: getClamp(125, 180),
            marginLeft: getClamp(19, 41),
        }
    }
}));

const WrapLayout = ( props ) => {

    const lang = props.pageContext.lang;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isBrowser = typeof window !== "undefined";
    const colorTransition = transitionColor[ props.path ] || palette.bbr.newblack;
    const link = (lang === 'fr') ? 'politique-confidentialite' : '/en/privacy-policy';

    const cookieName = 'browser-lang-redirect';
    const [cookies, setCookie] = useCookies([cookieName]);

    const componentButton = <>
                                {lang === 'fr' ? 'Accepter' : 'Accept'}

                                <SvgIcon className='cookie-icon' component={IconLongHorizontalArrow} viewBox="0 0 248 32" />
                            </>

    const siteHeight = () => {
        document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    }

    const ref = useRef(null);
    const { setCursorType } = useContext( MouseContext );

    useEffect(() => {
        if( isBrowser ){
            window.addEventListener('resize', () => { 
                siteHeight();
            });
            siteHeight();
        }

        const element = ref.current;
        const links = element.querySelectorAll(".container-cookie-consent a, .container-cookie-consent button");
        attachCustomCursor( links, setCursorType );
    },[])

    useEffect(() => {

        if( !cookies[cookieName] ){

            if(  !navigator.language.toLowerCase().includes( props.pageContext.lang )  ){

                // redirect en EN
                if( navigator.language.toLowerCase().includes( 'en' ) ){
                    window.location = getUrl('en',  props.pageContext.slug.en);
                }

                // redirect to FR
                if( navigator.language.toLowerCase().includes( 'fr' ) ){
                    window.location = getUrl('fr',  props.pageContext.slug.fr);
                }

            }

            setCookie(cookieName, true, { path: '/' });
        }

    },[])

    return (
        <>
            { matches &&
                <CustomCursor lang={ lang } />
            }

            { !matches &&
                <>
                    <TransitionMobile location={ props.location } colorTransition={ colorTransition } />
                    { props.children }
                </>
            }

            { matches &&
                <Transition location={ props.location } colorTransition={ colorTransition }>
                    { props.children }
                </Transition>
            }

            <BoxCookie ref={ ref }>
                <CookieConsent
                    buttonText={ componentButton }
                    style={{
                        background: palette.bbr.nebula,
                        opacity: '95%',
                        width: '42%',
                        justifySelf: 'flex-end',
                        left: 'auto',
                        right: 0,
                        zIndex: 2
                    }}
                    contentStyle={{
                        flex: 'auto',
                        margin: 0
                    }}
                    disableButtonStyles={true}
                    containerClasses='container-cookie-consent'
                >
                    { lang === 'fr' &&
                        parse(`On se sert de témoins pour bien comprendre comment notre site est utilisé. <a href=${link}>En savoir plus</a>`)
                    }
                    { lang === 'en' &&
                        parse(`We use cookies to help us understand how this site is used. <a href=${link}>Read more</a>`)
                    }
                    
                </CookieConsent>
            </BoxCookie>

        </>
    );
}

export default WrapLayout