import React from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Script } from "gatsby"

// MUI
import { CssBaseline, GlobalStyles } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import theme from '/src/theme/theme'
import customStyles from '/src/theme/customStyles'
import { MouseContextProvider } from '/src/context/mouseContext'
import { AnimationContextProvider } from '/src/context/animationContext'

export default function TopLayout( props ) {

    const inputGlobalStyles = <GlobalStyles styles={ customStyles } />
    
    return (
        <>
            <Helmet>
                <link rel="icon" href="/favicon/android-chrome-192x192.png" sizes="192x192" />
                <link rel="icon" href="/favicon/android-chrome-512x512.png" sizes="512x512" />
                <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png" />
                <link rel="icon" href="/favicon/favicon-32x32.png" sizes="32x32" />
                <link rel="icon" href="/favicon/favicon-16x16.png" sizes="16x16" />
            </Helmet>

            <Script id="loi-25">{`window.gdprAppliesGlobally=false;(function(){(function(e,r){var t=document.createElement("link");t.rel="preconnect";t.as="script";var n=document.createElement("link");n.rel="dns-prefetch";n.as="script";var i=document.createElement("link");i.rel="preload";i.as="script";var o=document.createElement("script");o.id="spcloader";o.type="text/javascript";o["async"]=true;o.charset="utf-8";var a="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+r;if(window.didomiConfig&&window.didomiConfig.user){var c=window.didomiConfig.user;var s=c.country;var d=c.region;if(s){a=a+"&country="+s;if(d){a=a+"&region="+d}}}t.href="https://sdk.privacy-center.org/";n.href="https://sdk.privacy-center.org/";i.href=a;o.src=a;var p=document.getElementsByTagName("script")[0];p.parentNode.insertBefore(t,p);p.parentNode.insertBefore(n,p);p.parentNode.insertBefore(i,p);p.parentNode.insertBefore(o,p)})("3d3d65bd-1079-46f6-beff-f9fb7d4a904f","aQxewpUz")})();`}</Script>
            
            <MouseContextProvider>
                <AnimationContextProvider>
                    <ThemeProvider theme={theme}>

                        <CssBaseline />
                        {inputGlobalStyles}

                        { props.children }

                    </ThemeProvider>
                </AnimationContextProvider>
            </MouseContextProvider>
        </>
    );
}

TopLayout.propTypes = {
    children: PropTypes.node
};