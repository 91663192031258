const palette = {
    bbr: {
        ferrari: '#FF472C',
        nebula: '#002CE3',
        zoran: '#00B8D3',
        lambor: '#FFD31C',
        smash: '#008C10',
        barb: '#FF71A7',
        almostwhite: '#FDFBF8',
        newblack: '#000000',
        kindadark: '#424242',
        inbetween: '#757575'
    }
};

export default palette;