import palette from '/src/theme/palette'

const customStyles = {

    'body':  {
        overflowX: 'hidden'
    },

    'body.modal-opened': {
        '& header #header': {
            zIndex: 1301,
            '.brand-name': { 
                display: 'none'
            },
            '.lang-switcher': { 
                display: 'block',
                color: palette.bbr.almostwhite,
                marginBottom: 0
            },
            'svg': { 
                backgroundColor: palette.bbr.newblack,
                'path': { 
                    fill: palette.bbr.almostwhite
                }
            }
        }
    },

    'h1, h2, h3, h4, h5, h6': {
        fontWeight: '400'
    },

    'p, .MuiTypography-root.MuiTypography-paragraph': {
        margin: 0,
        padding: 0
    },

    '.MuiContainer-root.MuiContainer-maxWidthXxl': {
        paddingRight: '16px',
        paddingLeft: '16px',
        '@media (max-width:600px)': {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },

    '.MuiContainer-root.MuiContainer-maxWidthXxl.MuiContainer-disableGutters': {
        padding: '0'
    }
 
};

export default customStyles;