import React, { createContext, useMemo, useState } from 'react'

const Context = createContext({
    animation: true,
    setAnimation: () => {},
})

const AnimationContextProvider = props => {

    const [animation, setAnimation] = useState(true);

    const value = useMemo(
        () => ({ animation, setAnimation }), 
        [animation]
    );

    return (
        <Context.Provider value={ value }>
            {props.children}
        </Context.Provider>
    )
}

export { AnimationContextProvider, Context }