// 100: Thin, Hairline, Ultra-light, Extra-light
// 200: Light
// 300: Book
// 400: Regular, Normal, Plain, Roman, Standard
// 500: Medium
// 600: Semi-bold, Demi-bold
// 700: Bold
// 800: Heavy, Black, Extra-bold
// 900: Ultra-black, Extra-black, Ultra-bold, Heavy-black, Fat, Poster

import { getClamp } from '/src/utils/functions'

const typos = {

    'h0': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 430,
            'lineHeight': 556
        },
        'mb': {
            'fontSize': 140,
            'lineHeight': 140
        }
    },
    'h1': {
        'dk': {
            'fontSize': 200,
            'lineHeight': 190
        },
        'mb': {
            'fontSize': 48,
            'lineHeight': 50
        }
    },
    'h1outcome': {
        'dk': {
            'fontSize': 160,
            'lineHeight': 164
        },
        'mb': {
            'fontSize': 48,
            'lineHeight': 50
        }
    },
    'h2': {
        'dk': {
            'fontSize': 160,
            'lineHeight': 164
        },
        'mb': {
            'fontSize': 32,
            'lineHeight': 35
        }
    },
    'h3': {
        'dk': {
            'fontSize': 112,
            'lineHeight': 120
        },
        'mb': {
            'fontSize': 24,
            'lineHeight': 31
        }
    },
    'h4': {
        'dk': {
            'fontSize': 88,
            'lineHeight': 88
        },
        'mb': {
            'fontSize': 17,
            'lineHeight': 20
        }
    },
    'h4outcome': {
        'dk': {
            'fontSize': 64,
            'lineHeight': 64
        },
        'mb': {
            'fontSize': 17,
            'lineHeight': 20
        }
    },
    'h4wide': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 88,
            'lineHeight': 88
        },
        'mb': {
            'fontSize': 38,
            'lineHeight': 40
        }
    },
    'h4wideoutcome': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 68,
            'lineHeight': 68
        },
        'mb': {
            'fontSize': 38,
            'lineHeight': 40
        }
    },
    'h5': {
        'dk': {
            'fontSize': 64,
            'lineHeight': 64
        },
        'mb': {
            'fontSize': 10,
            'lineHeight': 13
        }
    },
    'h5wide': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 64,
            'lineHeight': 88
        },
        'mb': {
            'fontSize': 38,
            'lineHeight': 40
        }
    },
    'h6wide': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 40,
            'lineHeight': 48
        },
        'mb': {
            'fontSize': 20,
            'lineHeight': 26
        }
    },
    'textxl': {
        'dk': {
            'fontSize': 48,
            'lineHeight': 56
        },
        'mb': {
            'fontSize': 18,
            'lineHeight': 23
        }
    },
    'textl': {
        'dk': {
            'fontSize': 40,
            'lineHeight': 48
        },
        'mb': {
            'fontSize': 16,
            'lineHeight': 21
        }
    },
    'textm': {
        'dk': {
            'fontSize': 32,
            'lineHeight': 40
        },
        'mb': {
            'fontSize': 14,
            'lineHeight': 18
        }
    },
    'texts': {
        'dk': {
            'fontSize': 24,
            'lineHeight': 26
        },
        'mb': {
            'fontSize': 11,
            'lineHeight': 14
        }
    },
    'textswide': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 24,
            'lineHeight': 24
        },
        'mb': {
            'fontSize': 14,
            'lineHeight': 14
        }
    },
    'textxs': {
        'dk': {
            'fontSize': 16,
            'lineHeight': 24
        },
        'mb': {
            'fontSize': 10,
            'lineHeight': 13
        }
    },
    'textxswide': {
        'fontFamily': 'AgrandirWide',
        'dk': {
            'fontSize': 18,
            'lineHeight': 24
        },
        'mb': {
            'fontSize': 10,
            'lineHeight': 13
        }
    },
    'textxxs': {
        'dk': {
            'fontSize': 13,
            'lineHeight': 16
        },
        'mb': {
            'fontSize': 13,
            'lineHeight': 16
        }
    }

}

export function setTypography( theme ){

    for (const typo in typos) {

        let fsvw = (typos[typo]['dk']['fontSize'] / ( 1920 / 100 )).toFixed(3);
        let lhvw = (typos[typo]['dk']['lineHeight'] / ( 1920 / 100 )).toFixed(3);
        let fontFamily = ( typos[typo]['fontFamily'] ) ? typos[typo]['fontFamily'] : 'inherit';

        // let minLH = ( typos[typo]['mb']['lineHeight'] / typos[typo]['mb']['fontSize'] );
        // let maxLH = ( typos[typo]['dk']['lineHeight'] / typos[typo]['dk']['fontSize'] );

        theme.typography[typo] = {

            fontFamily: fontFamily,
            // lineHeight: 'clamp( '+minLH+'em, '+(lhvw/fsvw).toFixed(3)+'vw, '+maxLH+'em )',
            //fontSize: 'clamp( '+typos[typo]['mb']['fontSize']+'px, '+fsvw+'vw, '+typos[typo]['dk']['fontSize']+'px )',
            
            fontSize: getClamp( typos[typo]['mb']['fontSize'], typos[typo]['dk']['fontSize'] ),
            // lineHeight: getClamp( minLH, maxLH ),

            [theme.breakpoints.up('xxl')]: {
                // fontSize:  typos[typo]['dk']['fontSize'],
                lineHeight: (typos[typo]['dk']['lineHeight']/typos[typo]['dk']['fontSize']).toFixed(3)
            },
            [theme.breakpoints.down('xxl')]: {
                // fontSize: fsvw+'vw',
                lineHeight: (lhvw/fsvw).toFixed(3)
            },
            [theme.breakpoints.down('sm')]: {
                // fontSize: typos[typo]['mb']['fontSize'],
                lineHeight: (typos[typo]['mb']['lineHeight']/typos[typo]['mb']['fontSize']).toFixed(3)
            },

        };

    }

}