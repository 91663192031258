import { each } from 'lodash'

const AttachCustomCursor = ( elements, setCursorType ) => {

    const addEventListenerME = ( el, cla ) => el.addEventListener('mouseenter', () => setCursorType( cla ) );

    each( elements, function( el, index ){

        switch(el.tagName) {
            
            case 'VIDEO':

                addEventListenerME(el, 'hover hover-playcasestudy rotation');

                break;

            default:

                if( el.childElementCount > 0 ){

                    each( el.children, function( child, index ){
        
                        if( child && child.classList ){
        
                            // img + links
                            if( child.classList.contains('gatsby-image-wrapper') ){
        
                                if( child.classList.contains('hover-people') ){
                                    addEventListenerME(el, 'hover hover-people rotation');
                                }else{
                                    addEventListenerME(el, 'hover hover-learnmore rotation');
                                }
        
                            }else{
                                addEventListenerME(el, 'hover');
                            }
                    
                        }else{
                            addEventListenerME(el, 'hover');
                        }
    
                    });
        
                }else{
                    addEventListenerME(el, 'hover');
                }

          }
    
        el.addEventListener('mouseleave', () => setCursorType('') );
        
    });

}

export default AttachCustomCursor;