import React, { useState, useContext, useEffect } from 'react'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

// MUI
import { Container, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { MouseContext } from '/src/context/mouseContext'

const TransitionContainer = styled( Container )(({ ...props }) => ({
    // height: '100vh',
    // position: 'absolute',
    '& .layout-transition': {
        position: 'absolute',
        '&.views-enter': {
            height: '100vh',
            position: 'absolute',
            width: '100vw',
            top: 0,
            left: 0,
            background: 'transparent',
            zIndex: 9,
            backdropFilter: 'blur(20px)'
        },
        '&.views-enter-active': {
            height: '100vh',
            position: 'absolute',
            width: '100vw',
            top: 0,
            left: 0,
            background: 'transparent',
            zIndex: 9,
            backdropFilter: 'blur(20px)'
        },
        '&.views-exit': {

        },
        '&.views-exit-active': {

        }
    },
    '.global-wrapper': {

    },
    '& .circle': {
        height: props.dim+'px',
        width: props.dim+'px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate3D(-50%, -50%, 0) scale(0)',
        transition: 'all '+props.timeout+'ms ease-in-out',
        zIndex: 1599,
        willChange: 'transform, scale',
        '& span': {
            borderRadius: '50%',
            height: '100%',
            width: '100%',
            display: 'block'
        }
    },
    '& .circles-enter': {
        transform: 'translate3D(-50%, -50%, 0) scale(1)'
    },
    '& .circles-enter-active': {
        transform: 'translate3D(-50%, -50%, 0) scale(1)'
    },
    '& .circles-exit': {
        transform: 'translate3D(-50%, -50%, 0) scale(0)'
    },
    '& .circles-exit-active': {
        transform: 'translate3D(-50%, -50%, 0) scale(0)'
    }
}));

const TransitionMobile = ( props ) => {
  
    const { location } = props;
    const [inProp, setInProp] = useState(false);
    const [dim, setDim] = useState(0);
    let colorTransition = props.colorTransition;
    const timeout = 400
    const { setCursorType } = useContext( MouseContext );

    const onEnter = (node, isAppearing) => {
        setInProp(true);
    }

    const onExited = () => {
        setInProp(false);
        colorTransition = 'transparent'
    }

    useEffect(() => {
        setDim( ( window.innerWidth <= window.innerHeight ) ? parseInt( window.innerHeight + (window.innerHeight/3) ) : parseInt( window.innerWidth + (window.innerWidth/3) ) );
    },[])

    useEffect(() => {
        setCursorType('');
    },[inProp])

    return (
        <TransitionContainer maxWidth="100%" disableGutters={true} dim={dim} timeout={ timeout } className="transition-container">

            <CSSTransition className={`circle`} in={inProp} timeout={ timeout } classNames="circles">
                <Box component="span" className="circle">
                    <Box component="span" sx={{ backgroundColor: colorTransition }} />
                </Box>
            </CSSTransition>

            <TransitionGroup component={null}>

                <CSSTransition 
                    key={ location.pathname }
                    onEnter={ onEnter }
                    onExited={ onExited }
                    timeout={{
                        appear: 0,
                        enter: timeout,
                        exit: timeout
                    }}
                    classNames="views">

                        <Box className='layout-transition' />

                </CSSTransition>

            </TransitionGroup>

        </TransitionContainer>
    )
}

export default TransitionMobile