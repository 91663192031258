import React, { useEffect, useState, useContext, useRef }  from 'react'

// MUI
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'

// GS
import { gsap } from 'gsap'

import { MouseContext } from '/src/context/mouseContext'

import Meetourpeople from '/static/icons/icon-meetourpeople.svg'
import Rencontrenous from '/static/icons/icon-rencontrenous.svg'
import Ensavoirplus from '/static/icons/icon-ensavoirplus.svg'
import PlayCaseStudy from '/static/icons/icon-playcasestudy.svg'
import Voirleprojet from '/static/icons/icon-voirleprojet.svg'
import Pause from '/static/icons/icon-pause.svg'
import Learnmore from '/static/icons/icon-learnmore.svg'

import Finger from '/static/icons/icon-finger.svg'
import Play from '/static/icons/icon-play.svg'

const rotation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
`;

const CustomCursor = styled( Box )(({ theme, lang }) => ({
    zIndex: theme.zIndex.cursor,
    position: 'fixed',
    top: '0',
    left: '0',
    opacity: '1',
    pointerEvents: 'none',
    borderRadius: '50%',
    display: 'inline-block',
    fontSize: '0',
    lineHeight: '0',
    mixBlendMode: 'difference',

    '& .inner':{
        width: '234px',
        height: '234px',
        borderRadius: '50%',
        display: 'inline-block',
        opacity: '1',
        transition: 'transform 350ms ease',
        transform: 'scale(0.35)',
        backgroundColor: theme.palette.bbr.almostwhite
    },

    '& .icon-rotation': {
        height: '100%',
        width: '100%',
        visibility: 'hidden',
        display: 'none'
    },
    
    '& .icon-finger': {
        visibility: 'hidden',
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3D(-50%,-50%,0)'
    },

    '& .icon-play': {
        visibility: 'hidden',
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3D(-50%,-50%,0)'
    },

    '& .icon-pause': {
        visibility: 'hidden',
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3D(-50%,-50%,0)'
    },

    '&.hover': {

        '& .inner':{
            transform: 'scale(1)',
            backgroundColor: theme.palette.bbr.almostwhite,
        },

        '&.hover-people':{
            '& .icon-rencontrenous': {
                animation: rotation+' 8s infinite linear',
                visibility: 'visible',
                display: 'block',
            },
        },

        '&.hover-learnmore': {
            '& .icon-ensavoirplus': {
                visibility: 'visible',
                display: 'block',
                animation: rotation+' 8s infinite linear'
            }
        },

        '&.hover-playcasestudy': {
            '& .icon-playcasestudy': {
                visibility: 'visible',
                display: 'block',
                animation: rotation+' 8s infinite linear'
            },
            '& .icon-finger': {
                display: 'none !important'
            },
            '& .icon-play': {
                visibility: 'visible',
                display: 'block'
            }
        },

        '&.hover-pausecasestudy': {
            '& .icon-playcasestudy': {
                visibility: 'visible',
                display: 'block',
                animation: rotation+' 8s infinite linear'
            },
            '& .icon-finger': {
                display: 'none !important'
            },
            '& .icon-play': {
                display: 'none !important'
            },
            '& .icon-pause': {
                visibility: 'visible',
                display: 'block'
            }
        },

        '&.rotation': {
            mixBlendMode: 'normal',
            '& .icon-finger': {
                visibility: 'visible',
                display: 'block'
            },
            // '& .icon-play': {
            //     visibility: 'visible',
            //     display: 'block',
            // }
            // '& circle':{
            //     fill: 'black'
            // }
        }

    }
    
}));

const Cursor = ( props ) => {

    const lang = props.lang;
    const isBrowser = typeof window !== "undefined";
    const ref = useRef(null);
    const [classes, setClasses] = useState('');

    const context = useContext( MouseContext );
    const cursorType = context.cursorType;

    useEffect(() => {

        const cursor = ref.current;        

        let initCursor = false;
        gsap.set(cursor, {xPercent: -50, yPercent: -50, opacity: 1});
        cursor.classList.remove("hover");

        let pos = 0;
        if (isBrowser) {
            pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
            // pos = { x: 0, y: 0 };
        }
        const mouse = { x: pos.x, y: pos.y };
        const speed = 1;

        const xSet = gsap.quickSetter(cursor, "x", "px");
        const ySet = gsap.quickSetter(cursor, "y", "px");

        if (isBrowser) {
            window.addEventListener("mousemove", e => {    
                mouse.x = e.x;
                mouse.y = e.y;
                if (!initCursor) {
                    gsap.to(cursor, {
                        duration: 0.3,
                        opacity: 1
                    });
                    initCursor = true;
                }
            });
            window.addEventListener('mouseout', e => {    
                // gsap.to(cursor, {
                //     duration: 0.3,
                //     opacity: 1
                // });
                initCursor = false;
            });
        }

        gsap.ticker.add(() => {
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
        
    },[]);

    // when cursorType changes
    useEffect(() => {

        setClasses(cursorType);

    }, [cursorType]);

    return (
        <CustomCursor ref={ref} lang={lang} id="custom-cursor" className={ classes } component="span">
            <span className="inner">

                { lang === 'fr' && <>
                    <Ensavoirplus className="icon-rotation icon-ensavoirplus" />
                    <Rencontrenous className="icon-rotation icon-rencontrenous" />
                    <Voirleprojet className="icon-rotation icon-playcasestudy" />
                </> }

                { lang === 'en' && <>
                    <Learnmore className="icon-rotation icon-ensavoirplus" />
                    <Meetourpeople className="icon-rotation icon-rencontrenous" />
                    <PlayCaseStudy className="icon-rotation icon-playcasestudy" />
                </> }
                
                <Finger className="icon-finger" />
                <Play className="icon-play" />
                <Pause className="icon-pause" />

            </span>
        </CustomCursor>
    )
}

export default Cursor