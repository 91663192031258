import palette from '/src/theme/palette'

const pageBackgroundColor = {
    '404': palette.bbr.ferrari,
    'capabilities': palette.bbr.lambor,
    'case-study': palette.bbr.newblack,
    'contact': palette.bbr.nebula,
    'glossary': palette.bbr.almostwhite,
    'home': palette.bbr.almostwhite,
    'language': palette.bbr.almostwhite,
    'people': palette.bbr.barb,
    'philosophy': palette.bbr.almostwhite,
    'policy': palette.bbr.almostwhite,
    'work': palette.bbr.almostwhite
};

const pageTextColor = {
    '404': palette.bbr.newblack,
    'capabilities': palette.bbr.newblack,
    'case-study': palette.bbr.almostwhite,
    'contact': palette.bbr.almostwhite,
    'glossary': palette.bbr.newblack,
    'home': palette.bbr.newblack,
    'language': palette.bbr.newblack,
    'people': palette.bbr.newblack,
    'philosophy': palette.bbr.newblack,
    'policy': palette.bbr.newblack,
    'work': palette.bbr.newblack
};

const menuTextColor = {
    'capabilities': palette.bbr.lambor,
    'people': palette.bbr.barb,
    'philosophy': palette.bbr.nebula,
    'work': palette.bbr.ferrari
};

const transitionColor = {
    '/realisations': palette.bbr.barb,
    '/en/work': palette.bbr.barb,
    '/philosophie': palette.bbr.lambor,
    '/en/philosophy': palette.bbr.lambor,
    '/talents': palette.bbr.nebula,
    '/en/capabilities': palette.bbr.nebula,
    '/equipe': palette.bbr.ferrari,
    '/en/people': palette.bbr.ferrari
};

export { pageBackgroundColor, pageTextColor, menuTextColor, transitionColor };