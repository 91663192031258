import React, { useState, useContext, useEffect } from 'react'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

// MUI
import { Container, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { MouseContext } from '/src/context/mouseContext'

const TransitionContainer = styled( Container )(({ ...props }) => ({
    '.global-wrapper': {
        transition: 'opacity '+props.timeout+'ms ease-in-out',
        '&.pageto': {
            position: 'absolute',
            '&.views-enter': {
                opacity: '0'
            },
            '&.views-enter-active': {
                opacity: '0'
            },
            '&.views-exit': {
                opacity: '1'
            },
            '&.views-exit-active': {
                opacity: '1'
            }
        },
        '&.pagefrom': {
            position: 'absolute',
            '&.views-enter': {
                opacity: '1'
            },
            '&.views-enter-active': {
                opacity: '1'
            },
            '&.views-exit': {
                opacity: '0'
            },
            '&.views-exit-active': {
                opacity: '0'
            }
        },
        // willChange: 'opacity'
    },
    '& .circle': {
        height: props.dim+'px',
        width: props.dim+'px',
        position: 'fixed',
        borderRadius: '50%',
        top: '50%',
        left: '50%',
        transform: 'translate3D(-50%, -50%, 0) scale(0)',
        transition: 'all '+props.timeout+'ms ease-in-out',
        zIndex: 1599,
        willChange: 'transform, scale'
    },
    '& .circles-enter': {
        transform: 'translate3D(-50%, -50%, 0) scale(1)'
    },
    '& .circles-enter-active': {
        transform: 'translate3D(-50%, -50%, 0) scale(1)'
    },
    '& .circles-exit': {
        transform: 'translate3D(-50%, -50%, 0) scale(0)'
    },
    '& .circles-exit-active': {
        transform: 'translate3D(-50%, -50%, 0) scale(0)'
    },

    '& .layout-transition': {
        position: 'absolute',
        '&.viewss-enter': {
            height: '100vh',
            position: 'fixed',
            width: '100vw',
            top: 0,
            left: 0,
            background: 'transparent',
            zIndex: 9,
            backdropFilter: 'blur(20px)'
        },
        '&.viewss-enter-active': {
            height: '100vh',
            position: 'fixed',
            width: '100vw',
            top: 0,
            left: 0,
            background: 'transparent',
            zIndex: 9,
            backdropFilter: 'blur(20px)'
        },
        '&.viewss-exit': {

        },
        '&.viewss-exit-active': {

        }
    },

}));

const Transition = ( props ) => {
  
    const { children, location } = props;
    const [inProp, setInProp] = useState(false);
    const [dim, setDim] = useState(0);
    let colorTransition = props.colorTransition;
    const timeout = 500
    const { setCursorType } = useContext( MouseContext );

    const onEnter = (node, isAppearing) => {
        document.querySelector(".transition-container > div:first-of-type").classList.add('pageto');
        document.querySelector(".transition-container > div:last-child").classList.add('pagefrom');
        setInProp(true);
    }

    const onExited = () => {
        document.querySelector(".transition-container > div").classList.remove('pageto');
        setInProp(false);
        // setTimeout(function(){
            // setInProp(false);
            // window.scrollTo(0, 0);
        // }, 500)
        colorTransition = 'transparent'
    }

    useEffect(() => {
        setDim( ( window.innerWidth <= window.innerHeight ) ? parseInt( window.innerHeight + (window.innerHeight/2) ) : parseInt( window.innerWidth + (window.innerWidth/2) ) );
    },[])

    useEffect(() => {
        setCursorType('');
    },[inProp])

    return (
        <TransitionContainer maxWidth="100%" disableGutters={true} dim={dim} timeout={ timeout } className="transition-container">

            <CSSTransition className={`circle`} in={inProp} classNames="circles" timeout={ timeout }>
                <Box component="span" sx={{ backgroundColor: colorTransition }} className="circle" />
            </CSSTransition>

            <TransitionGroup component={null}>

                <CSSTransition 
                    key={ location.pathname }
                    onEnter={ onEnter }
                    onExited={ onExited }
                    timeout={{
                        appear: 0,
                        enter: timeout,
                        exit: timeout,
                    }}
                    classNames="views">

                        { children }
                
                </CSSTransition>

            </TransitionGroup>

            <TransitionGroup component={null}>
                <CSSTransition 
                    key={ location.pathname }
                    timeout={{
                        appear: 0,
                        enter: timeout,
                        exit: timeout
                    }}
                    classNames="viewss">
                        <Box className='layout-transition' />
                </CSSTransition>
            </TransitionGroup>

        </TransitionContainer>
    )
}

export default Transition